import React, { Component } from 'react'

import './backIcon.css';

export default class BackIcon extends Component {
	
	render() {
		return (
			<div onClick={this.props.handleBack} className="back-icon">
				<img src="/img/Strelica2.png" alt="nazad"/>
			</div>
		)
	}
}
