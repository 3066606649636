import React from 'react';

import './gridRow.css';

import ImageViewer from '../ImageViewer/ImageViewer';


class GridRow extends React.Component {

	constructor(props) {
		super(props);
		this.wrapperRef = React.createRef();
		this.state = { unitWidth: 0, currentImage: 0, isViewerOpen: false }
	}

	openImageViewer = (index) => {
		this.setState({ currentImage: index});
		this.setState({ isViewerOpen: true });
	};

	closeImageViewer = () => {
		this.setState({ currentImage: 0});
		this.setState({ isViewerOpen: false });
	};

	componentDidMount() {
		this.updateUnitWidth();
	}

	updateUnitWidth() {
		const rowWidth = this.wrapperRef.current?.clientWidth;
		// console.log(this.wrapperRef.current);
		const availableWidth = rowWidth - (this.props.images?.length - 1) * 20 - 8;

		let totalAspects = 0;
		for (let index = 0; index < this.props.images?.length; index++) {
			totalAspects += this.calcImgAspect(this.props.images[index]?.width, this.props.images[index].height);
		}

		// console.log(`totalAspects: ${totalAspects}`);
		const widthUnit = availableWidth / totalAspects;
		// console.log(availableWidth, rowWidth);

		this.setState({ unitWidth: widthUnit });
	}

	getImgWidth = (aspect) => {
		return this.state.unitWidth * aspect;
	}

	calcImgAspect(imageWidth, imageHeight) {
		// console.log(imageWidth, imageHeight);
		return imageWidth / imageHeight;
	}

	getMaxImageHeight = (imageWidth, imageAspectRatio) => {
		return Math.ceil(imageWidth / imageAspectRatio);
	}

	render() {
		return (
			<div className="grid-row-wrapper" style={{ marginBottom: this.props.lastRow? '0px': '20px', maxHeight: Math.ceil() }} ref={this.wrapperRef}>
				{ this.props.images?.map((image, index) => {
					const imageAspectRatio = this.calcImgAspect(image.width, image.height);
					const imageWidth = this.getImgWidth(imageAspectRatio);
					const imageHeight = this.getMaxImageHeight(imageWidth, imageAspectRatio);
					return <img title={image.id} style={{ width: `${imageWidth}px`, maxHeight: `${imageHeight}px`}} onClick={() => this.openImageViewer(this.props.imageList.findIndex(src => src === image.url))} src={image.url} alt="" key={image.id} />
				})}

				{this.state.isViewerOpen && (
				<ImageViewer
					src={ this.props.imageList }
					currentIndex={ this.state.currentImage }
					onClose={ this.closeImageViewer }
					backgroundStyle={{ backgroundColor: "rgba(0,0,0, .85)"}}
					/>
				)}
			</div>
		);
	}
}

export default GridRow;