import React from 'react';
import { NavLink } from 'react-router-dom';

import './projectName.css';

class ProjectName extends React.Component {
	render() {
		console.log(this.props.project);
		return (
			<NavLink className="project-name-wrapper"  onMouseEnter={() => document.documentElement.style.setProperty('--background', `url(${this.props.project.headerPhoto})`)} onMouseLeave={() => document.documentElement.style.setProperty('--background', 'white')}  to={{pathname:`/project/${this.props.project.id}`}}>
				<div className="data">
					<div className="title">{this.props.project.name}</div>
					<div className="secondary-info">{this.props.project.location}</div>
					<div className="secondary-info">{this.props.project.year}</div>
				</div>
			</NavLink>
		);
	}
}

export default ProjectName;