import React, { useCallback } from 'react';

import './newGrid.css';

import GridRow from './gridRow/GridRow';

class NewGrid extends React.Component {
	


	calcImgAspect(image) {
		return image.width / image.height;
	}

	getRowNumbers(length) {
		const x = Math.floor(length / 3);
		const y = length % 3;

		if (y === 0) {
			return {
				numberOfTwoImageRows: 0,
				numberOfThreeImageRows: x
			}
		}

		else if (y === 1) {
			return {
				numberOfTwoImageRows: 2,
				numberOfThreeImageRows: x -1
			}
		}

		else if (y === 2) {
			return {
				numberOfTwoImageRows: 1,
				numberOfThreeImageRows: x
			}
		}
	}

	shuffle(array) {
		var currentIndex = array.length, temporaryValue, randomIndex;
	  
		// While there remain elements to shuffle...
		while (0 !== currentIndex) {
	  
		  // Pick a remaining element...
		  randomIndex = Math.floor(Math.random() * currentIndex);
		  currentIndex -= 1;
	  
		  // And swap it with the current element.
		  temporaryValue = array[currentIndex];
		  array[currentIndex] = array[randomIndex];
		  array[randomIndex] = temporaryValue;
		}
	  
		return array;
	}

	getRowData(images, numberOfTwoImageRows, numberOfThreeImageRows) {
		let templateRows = [];

		for (let i = 0; i < numberOfTwoImageRows; i++) {
			templateRows.push(2);
		}

		for (let i = 0; i < numberOfThreeImageRows; i++) {
			templateRows.push(3);
		}

		templateRows = this.shuffle(templateRows);
		let rows = new Array(numberOfThreeImageRows + numberOfTwoImageRows);
		for (let i = 0; i < rows.length; i++) {
			rows[i] = [];			
		}

		let imageIndex = 0;
		for (let i = 0; i < templateRows.length; i++) {
			for (let j = 0; j < templateRows[i]; j++) {
				rows[i].push(images[imageIndex]);
				imageIndex++;
			}
			
		}

		return rows;
	}




	render() {
		console.log('rerendering');
		// const aspectSortedImages = this.props.images?.sort((a, b) => { return this.calcImgAspect(a) - this.calcImgAspect(b)});
		// console.log(aspectSortedImages);
		let rows = [];
		if (this.props.images) {

			const {numberOfTwoImageRows, numberOfThreeImageRows} = this.getRowNumbers(this.props.images?.length);
			rows = this.getRowData(this.props.images, numberOfTwoImageRows, numberOfThreeImageRows);
		}

		return (
			<div className="new-grid-wrapper">
				
				{ rows.map((row, index) => {
					return <GridRow key={index} images={row} lastRow={index === (rows.length - 1)} imageList={this.props.images.map(image => image.url)} />;
				})}

				{/* <GridRow images={rows[1]} /> */}
				


			</div>
		)
	}
}

export default NewGrid;