import './app.css';

import React from 'react';
import { Route, HashRouter } from 'react-router-dom';

import LandingPage from './componenets/landingPage/landingPage';
import HomePage from './componenets/homePage/homePage';
import ProjectPage from './componenets/projectPage/ProjectPage';
import HomePageV2 from './componenets/homePageV2/HomePageV2';

class App extends React.Component {

	render() {
		return (
			<div className="">
				<HashRouter>
					<Route path="/" exact component={LandingPage} />
					<Route path="/home/:state?" component={HomePageV2}/>
					<Route path="/project/:id" component={ProjectPage}/>
				</HashRouter>
			</div>
		);
	}
}

export default App;