import './landingPage.css';

import React from 'react';
import LandingLogo from '../landingLogo/landingLogo';
import { NavLink } from 'react-router-dom';

class LandingPage extends React.Component {
	constructor() {
		super();
		this.bgRef = React.createRef();
		this.state = { bgLoaded: false };
	}
	
	componentDidMount() {
		this.bgRef.current.addEventListener("load", this.bgLoaded);
	}

	bgLoaded = () => {
		// console.log('background image should be done loading');
		this.setState({ bgLoaded: true });
	}

	render() {
		return (
			<div >
				<img className="background-image" alt="" ref={this.bgRef} src="/img/cover2.jpg"></img>
				{ this.state.bgLoaded ? 
				// <div className="landingPage-wrapper">
				// 	<LandingLogo />
				// 	<NavLink className="enter" to="/home">enter</NavLink>
				// </div>
				<div to="/home" className="landingPage-wrapper">
					<NavLink className="navlink" to="/home">
						<LandingLogo />
					</NavLink>
				</div>
				:
				<div className="loader-wrapper">
					{/* loading... */}
					{/* <Loader type="Puff"
						color="#999999"
						height={100}
						width={100}
						secondaryColor="black"
						// timeout={3000} //3 secs
						/> */}
						<img src="/img/loadingAnimation.gif" alt="loading..." width="100"/>
				</div>
				}
				
			</div> 
		);
	}
}

export default LandingPage;