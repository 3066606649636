import React from 'react';
import Header from '../header/Header';
import Logo from '../logo/Logo';

import './projectPage.css';

import { getProject } from '../../data/projects';

import NewGrid from '../newGrid/NewGrid';
import PhoneGrid from '../PhoneGrid/PhoneGrid';
import ProjectDescription from './projectDescription/ProjectDescription';
import ExtandableContent from './extandableContent/ExtandableContent';
import BackIcon from './backIcon/BackIcon';

class ProjectPage extends React.Component {

	state = {
		project: null,
		pageWidth: null,
	}

	resizeHandler = () => {
		console.log('resize handler activated');
		setTimeout(() => {
			// this.setState({ pageWidth: this.window?.width });
			window.location.reload();
		}, 1000);
	}
	
	componentDidMount = () => {
		this.setState({ project: getProject(this.props.match.params.id), pageWidth: this.window?.width });
		// this.setState({ })
		window.addEventListener('resize', this.resizeHandler);
	}

	handleBack = () => {
		// this.props.history.push('/home');
		this.props.history.goBack();
	}
	
	render = () => {
		var project = this.state.project;
		return (
			<div className="projectPage-container">
				{ window.screen.width > 600 && <div className="project-wrapper">
					<div className="desktopPage-container">

						<div className="left-panel">
							<Header/> 
							<div className="main-body">
							<div className="back-icon-container">
								<BackIcon handleBack={this.handleBack}/>
							</div>

							<div className="left-lower-panel">
								<div className="info">
									<div className="secondary-header">{project?.name}</div>
									<p className="secondary-info">location: {project?.location}</p>
									<p className="secondary-info">year: {project?.year}</p>
									<p className="secondary-info">status: {project?.status}</p>
									<p className="secondary-info">client: {project?.client}</p>
									<p className="secondary-info">gross area: {project?.grossArea}</p>
									<p className="secondary-info">architects: {project?.architects}</p>
									{ project?.photography && <p className="secondary-info">photography: {project?.photography}</p> }
								</div>

							</div>
							</div>
							<Logo/>

						</div>



					</div>

					<div className="right-panel">
						<div className="desktop-image-grid-container">
							<NewGrid images={project?.photos}/>
						</div>
						{/* <div className="project-description">
							<ProjectDescription className="project-description" projectDescription={project?.description} title={project?.descriptionTitle}></ProjectDescription>
						</div> */}
							<ProjectDescription className="project-description" projectDescription={project?.description} title={project?.descriptionTitle}></ProjectDescription>

					</div>
				
				</div>
				}

				{ window.screen.width <= 600 && <div className="mobile-project-wrapper">
					<div className="mobile-header">

						<div className="mobile-top-bar">
							{/* <div onClick={this.handleBack} className="back-icon">
								<img src="/img/Strelica2.png" alt=""/>
							</div> */}
							<div className="back-icon-container">
								<BackIcon handleBack={this.handleBack}/>

							</div>

							<div className="secondary-header">{project?.name}</div>
						</div>
						<ExtandableContent project={this.state.project} />
					</div>

					<div className="mobile-grid-container">
						<PhoneGrid images={project?.photos} />
					</div>
					<div className="secondary-header">{project?.descriptionTitle}</div>
					<p className="justified-text" > {project?.description} </p>

					<Logo />

				</div> }
			</div>
		);
	}
}

export default ProjectPage;