import React from 'react';
import { NavLink } from 'react-router-dom';

import './header.css';

class Header extends React.Component {
	render = () => {
		return (
			<header>
				{/* <div className="company-name">ArchitectsAgainstAcronymAbuse</div> */}
				<NavLink to="/" className="company-name">ArchitectsAgainstAcronymAbuse</NavLink>
				{/* <div className="language-selector">
					<div className="active">en</div>
					<div className="">sr</div>
				</div> */}
			</header>
		);
	}
}

export default Header;