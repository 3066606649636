import React from 'react';

import './imageViewer.css';

import { hotkeys } from 'react-keyboard-shortcuts'; 

class ImageViewer extends React.Component {

	state = {
		currentIndex: this.props.currentIndex
	}

	previousImage = (event) => {
		event.stopPropagation();
		console.log(this.props);

		this.setState({ currentIndex: this.state.currentIndex === 0 ? this.props.src.length -1 : this.state.currentIndex - 1 });
	}

	nextImage = (event) => {
		event.stopPropagation();
		this.setState({ currentIndex: (this.state.currentIndex + 1) % this.props.src.length });
	}

	hot_keys = {
		'esc': {
			priority: 1,
			handler: (event) => this.props.onClose()
		},
		'left': {
			priority: 1,
			handler: (event) => this.previousImage(event)
		},
		'right': {
			priority: 1,
			handler: (event) => this.nextImage(event)
		}
	}

	render() {
		console.log(this.props);
		return (
			<div className="image-viewer-wrapper" onClick={this.props.onClose}>
				<div className="side-wrapper" >
					<div className="control-wrapper" onClick={(event) => this.previousImage(event)}>
						<img src="/img/strelicaLevo.png" alt="previous" />
					</div>
				</div>
				<div className="photo-container">
					<img src={this.props.src[this.state.currentIndex]} alt="failed to load" onClick={(event) => event.stopPropagation()} />

				</div>
				<div className="side-wrapper right">
					<div className="control-wrapper" onClick={(event) => this.nextImage(event)}>
					<img src="/img/strelicaDesno.png" alt="next" />

					</div>
				</div>
			</div>
		);
	}
}

export default hotkeys(ImageViewer);