import React from 'react';

import './navigation.css'

import ProjectListItem from '../ProjectListItem/ProjectListItem';
import { projects } from '../../data/projects';

class Navigation extends React.Component {



	state = { shouldExtend: null }
	
	aboutClickHandler = () => {
		this.setState({ shouldExtend: this.state.shouldExtend === 'about' ? null : 'about' });
	}

	contactClickHandler = () => {
		this.setState({ shouldExtend: this.state.shouldExtend === 'contact' ? null : 'contact' });
	}

	projectsClickHandler = () => {
		this.setState({ shouldExtend: this.state.shouldExtend === 'projects' ? null : 'projects' });
	}

	handleProjectMouseOver = (e) => {
		console.log('project mouse over', e);
	}

	

	getProjectList = () => {
		return projects.map((project, index) => {
			return (
				<div className={`project-list-item-container ${index === projects.length -1 ? 'last-item' : ''}`}>
					<ProjectListItem key={project.id} project={project}></ProjectListItem>
				</div>
			);
		})
	}

	render() {
		return (
			<div className="no-select navbar-wrapper">

				<div className="first-meni-item-wrapper">
					<div className={`meni-item ${this.state.shouldExtend === 'projects' ? 'is-selected' : ''}`} onClick={this.projectsClickHandler}>projects</div>
					<div className={`extension-content ${this.state.shouldExtend === 'projects'? 'is-active' : ''}`}>
						<div className="hidden-content">

							{/* <div onMouseOver={this.handleProjectMouseOver} className="project">prvi projekat</div> */}
							{ this.getProjectList() }
							
						</div>
					</div>
				</div>

				<div className="second-meni-item-wrapper">
					<div className={`meni-item ${this.state.shouldExtend === 'about' ? 'is-selected' : ''}`} onClick={this.aboutClickHandler}>about</div>
					<div className={`extension-content ${this.state.shouldExtend === 'about'? 'is-active' : ''}`}>
						<div className="hidden-content justified-text">
							<p><strong>ArchitectsAgainstAcronymAbuse</strong> is an office with a strong commitment of challenging common understanding of architectural practice and built environment. Our work is characterized by bold conceptual ideas (which establish unique solution for every project), and immense attention to translating those design idea into physically built architecture. </p>
							<p><strong>ArchitectsAgainstAcronymAbuse</strong> operates within the traditional understanding of architecture and urban design, while simultaneously challenging its contemporary representation and perception. We reject common theme of producing architecture as a synthesis of banal “architectural acronyms”, while accepting our responsibility to deal with the same. Unprejudiced by convention, we arrive at solutions that transcend initial agendas.</p>
							<p><strong>ArchitectsAgainstAcronymAbuse</strong> challenges constrains (site conditions, budgets, politics, social convention, historical heritage) as design opportunities. Instead of masking them, we search for a solution which would unitize all of them, arriving at a new quality, often unimaginable at the beginning of the process. </p>
							<p><strong>ArchitectsAgainstAcronymAbuse</strong> is led by two partners - Petar Pejić and Ivana Marjanović</p>
						</div>
					</div>
				</div>

				<div className="third-meni-item-wrapper">
					<div className={`meni-item third-meni-item ${this.state.shouldExtend === 'contact' ? 'is-selected' : ''}`} onClick={this.contactClickHandler}>contact</div>
					<div className={`extension-content ${this.state.shouldExtend === 'contact'? 'is-active' : ''}`}>
						<div className="hidden-content">
							<h3>OFFICE</h3>
							<p>Nikole Tesle 34</p>
							<p>25000 Sombor Serbia</p>
							<p>+381-69-468-82-66</p>
							<p>petarpejic@aaaa.rs</p>
							<h3>NEW PROJECTS</h3>
							<p className="lead">Petar Pejić</p>
							<p>petarpejic@aaaa.rs</p>
							<h3>PRESS</h3>
							<p className="lead">Ivana Marjanović</p>
							<p>ivanamarjanovic@aaaa.rs</p>
						</div>
					</div>
				</div>

			</div>
		);
	}
}

export default Navigation;