import React from 'react';

import './extandableContent.css';

class ExtandableContent extends React.Component {
	state = {
		shouldExtend: false
	}
	
	handleExtendTrigger = () => {
		this.setState({ shouldExtend: !this.state.shouldExtend });
	}

	render() {
		return (
			<div className={`mobile-extendable-content ${this.state.shouldExtend ? 'extended' : ''}`} >
				<div className="hidden-content">
					<p className="secondary-info">location: {this.props.project?.location}</p>
					<p className="secondary-info">year: {this.props.project?.year}</p>
					<p className="secondary-info">status: {this.props.project?.status}</p>
					<p className="secondary-info">client: {this.props.project?.client}</p>
					<p className="secondary-info">gross area: {this.props.project?.grossArea}<span className="no-capitalize">m</span><sup>2</sup></p>
					<p className="secondary-info">architects: {this.props.project?.architects}</p>
					{ this.props.project?.photography && <p className="secondary-info">photography: {this.props.project?.photography}</p> }
				</div>
				<div onClick={this.handleExtendTrigger} className="arrow-wrapper">
					<img className="arrow" src="/img/strelicaDole.png" alt=""/>
				</div>

			</div>
		);
	}
}

export default ExtandableContent;