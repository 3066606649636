import React from 'react';

import './phoneGrid.css';

class PhoneGrid extends React.Component {
	render() {
		console.log(`I've just rendered phone grid`);
		return (
			<div className="phone-grid-wrapper">
				<div className="images">
					{ this.props.images?.map((image, index) => {
						return <img className={ this.props.images?.length === index-1 ? 'last-item' : ''} src={image.url} alt="" key={image.id}/>
					})}
				</div>
			</div>
		);
	}
}

export default PhoneGrid;