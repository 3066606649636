import './homePage.css';
import React from 'react';

import Navigation from '../navigation/navigation';
import Logo from '../logo/Logo';

import Header from '../header/Header';

class HomePage extends React.Component {

	render() {
		console.log(this.props);
		return (
			<div className="page-container divide">
				{/* { this.renderHeader() } */}
				<Header/>
					<div className="menu-container">
						<div className="navigation-wrapper">
							<Navigation projects={this.props.projects} />
						</div>
					</div>

				<div className="animated-logo-container">
					<Logo className="animated-logo" />

				</div>
			</div>

		);
	}
}

export default HomePage;