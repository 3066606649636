import './Logo.css';

import React from 'react';

class Logo extends React.Component {
	render() {
		return (
			<div className="logo-wrapper">

				<div className="logo">
					<img src="/img/oneA.png" alt="no alt yet"/> 
					<img src="/img/oneA.png" alt="no alt yet"/>
					<img src="/img/oneA.png" alt="no alt yet"/>
					<img src="/img/oneA.png" alt="no alt yet" />
					<img src="/img/lastA.png" alt="no alt yet" className="last-a"/>
				</div>

			</div>
		);
	}
}

export default Logo;