import './landingLogo.css';

import React from 'react';

class Logo extends React.Component {
	render() {
		return (
			<div className="landing-logo-wrapper">

				<div className="landing-logo">
					<img src="img/oneA.png" alt="no alt yet" className="landing-logo-first-letter"/> 
					<img src="img/oneA.png" alt="no alt yet" className="landing-logo-second-letter"/>
					<img src="img/oneA.png" alt="no alt yet" className="landing-logo-third-letter"/>
					<img src="img/oneA.png" alt="no alt yet" className="landing-logo-fourth-letter"/>
					<img src="img/lastA.png" alt="no alt yet" className="landing-logo-fifth-letter last-a"/>
				</div>

				<div className="landing-logo-text">
					ArchitectsAgainstAcronymAbuse
				</div>

			</div>
		);
	}
}

export default Logo;