import React from 'react';

import './projectDescription.css';

class ProjectDescription extends React.Component {
	state = {
		projectDescriptionOpen: false
	}
	

	handleProjectDescriptionClick = () => {
		this.setState({ projectDescriptionOpen: !this.state.projectDescriptionOpen });
	}

	render() {
		return (
			<div className={`project-description-wrapper ${this.state.projectDescriptionOpen ? 'open' : ''}`}>
				<div onClick={this.handleProjectDescriptionClick} className="project-description-header-wrapper">
					<div className="title secondary-header project-description-title-text">{this.props.title}</div>
					<img className="arrow" src="/img/strelicaGore.png" alt=""/>
				</div>
				<div className="hidden-info secondary-info">
					{this.props.projectDescription}
				</div>

			</div>
		);
	}
}

export default ProjectDescription;