import React from 'react';
import { NavLink } from 'react-router-dom';

import './ProjectListItem.css';

class ProjectListItem extends React.Component {
	render() {
		console.log(this.props.project);
		return (
			<NavLink className="project-list-item-wrapper"  to={{pathname:`project/${this.props.project.id}`}}>
				<div className="data">
					<div className="title" onMouseEnter={() => document.documentElement.style.setProperty('--background', `url(${this.props.project.headerPhoto})`)} onMouseLeave={() => document.documentElement.style.setProperty('--background', 'white')} >{this.props.project.name}</div>
					<div className="secondary-info">{this.props.project.location}</div>
					<div className="secondary-info">{this.props.project.year}</div>
				</div>
				<div className="image-container">
					<img src={this.props.project.headerPhoto} alt=""/>
				</div>
			</NavLink>
		);
	}
}

export default ProjectListItem;