export var projects = [
	{
		id: 1,
		name: 'notary office Karanovic',
		year: '2019-2020',
		location: 'Sombor, Serbia',
		client: 'private',
		grossArea: '100sqm',
		architects: 'Petar Pejic, Ivana Marjanovic',
		photography: 'Relja Ivanic',
		status: 'completed',
		headerPhoto: 'https://i.imgur.com/kuno77H.jpg',
		descriptionTitle: 'creating artificial monumentality',
		description: `New notary office is imagined inside an existing building, which has no apparent architectural qualities. Therefore, the design itself is producing an artificial monumentality as a part of conceptual abstraction. All of the irregularities found inside the office space are “fixed” via vault like ceiling, where radius of each circle is adopted to some found existing geometric condition. This results is an unique spatial condition, connecting all of the elements into an unique physical solution.
		
		Differentiation between public space (lobby and hallway) and private space (offices) is achieved via longitudinal storage space placed as a physical and spatial barrier in between. Doors are punctured inside the closet, resulting in a more monumental entrance. Different materialization between public and private spaces is culminating in the vertical barrier (closet) where they are meeting and mashing together vertically. 
		
		None of the vertical elements is full story tall, leaving possibility to perceive ceiling geometry beyond single room space, resulting in visually bigger spaces, and more dynamic spatial composition. Instead of masking non-conventional depth of the office space, new design is emphasizing that condition, making it the starting point of the conceptual idea.`,
		'photos': [
			{
				id: 1,
				url: "https://imgur.com/MYTy14S.jpg",
				desciption: "no desc provided",
				// aspect: 1.25,
				width: 1205,
				height: 1820,
			},
			{
				id: 2,
				url: "https://i.imgur.com/FrhQIf9.jpg",
				desciption: "no desc provided",
				// aspect: 1.49,
				width: 1618,
				height: 1213,
			},
			{
				id: 3,
				url: "https://i.imgur.com/OcE2t2H.jpg",
				desciption: "no desc provided",
				// aspect: 0.66,
				width: 1819,
				height: 1213,
			},

			{
				id: 4,
				url: "https://i.imgur.com/FQNnM6E.jpg",
				desciption: "no desc provided",
				// aspect: 0.66,
				width: 1213,
				height: 1819,
			},
			{
				id: 5,
				url: "https://i.imgur.com/YLfen6s.jpg",
				desciption: "no desc provided",
				width: 1519,
				height: 1213,
			},
			{
				id: 6,
				url: "https://i.imgur.com/J2iArqQ.jpg",
				desciption: "no desc provided",
				width: 1213,
				height: 1819
			},
			{
				id: 7,
				url: "https://i.imgur.com/kuno77H.jpg",
				desciption: "no desc provided",
				width: 1819,
				height: 1213
			},
			{
				id: 8,
				url: "https://i.imgur.com/LnqUPU2.jpg",
				description: "no desc provided",
				width: 1213,
				height: 1819,
			},
			{
				id: 9,
				url: "https://i.imgur.com/JYstvBq.jpg",
				description: "no desc provided",
				width: 1820,
				height: 1635,
			},
			{
				id: 10,
				url: "https://i.imgur.com/yFQaPRG.jpg",
				description: "no desc provided",
				width: 1213,
				height: 1819,
			},
			{
				id: 11,
				url: "https://i.imgur.com/Z8lmrj3.jpg",
				description: "no desc provided",
				width: 1213,
				height: 1819,
			},
			{
				id: 12,
				url: "https://i.imgur.com/8sDtwDK.jpg",
				description: "no desc provided",
				width: 1366,
				height: 1820,
			},
			{
				id: 13,
				url: "https://i.imgur.com/thmfL7t.jpg",
				description: "no desc provided",
				width: 1624,
				height: 1213,
			},
			{
				id: 14,
				url: "https://i.imgur.com/qi1azMl.jpg",
				description: "no desc provided",
				width: 1213,
				height: 1819,
			},
			{
				id: 15,
				url: "https://i.imgur.com/4IL5ZiW.jpg",
				description: "no desc provided",
				width: 1213,
				height: 1819,
			},
			{
				id: 16,
				url: "https://i.imgur.com/KLErFRY.jpg",
				description: "no desc provided",
				width: 1213,
				height: 1819,
			},
			{
				id: 17,
				url: "https://i.imgur.com/I8hFnGa.gif",
				description: "no desc provided",
				width: 1200,
				height: 536,
			},
			{
				id: 18,
				url: "https://i.imgur.com/stGjqvu.jpg",
				description: "no desc provided",
				width: 2048,
				height: 1536,
			},
			{
				id: 19,
				url: "https://i.imgur.com/XeU991v.jpg?1",
				description: "no desc provided",
				width: 940,
				height: 1520,
			},

		]
	},
	
	{
		id: 2,
		name: 'Commercial Building Odzaci',
		year: '2018-2020',
		location: 'Odzaci, Serbia',
		client: 'private',
		grossArea: '1000sqm',
		architects: 'Petar Pejic, Ivana Marjanovic',
		photography: '',
		status: 'under development',
		headerPhoto: 'https://i.imgur.com/0Fn452c.jpg',
		descriptionTitle: 'Challenging Traditional Shape',
		description: `Building is placed in highly residential surrounding, where surrounding is dominated by single floor traditional “elbow” houses found in Vojvodina. Our building is more than 1000sq m, so it is clearly out of place considering its size and volume. We recognized this intrusion and decided to reflect it in the form of the building, offering multiple reading condition of the project. Frontal view is clearly displacing the image of the traditional house, while both corner views offer different reading as well. From the main street, building is elevated from the ground floor, clearly stating corner as one of the contextual values, while from the other side, building is concieved as a roof plane, stressing connection with the surrounding typology. Further reading reveals displacement in volume / surface treatment, where same element is seen as part of the three dimensional volume, while other viewpoint will reveal it as part of two dimensial surface wrapping the basic form. 
		
		Ground floor and first floor are commercial programe, while the last floor should be multi functional space with maximum flexibility. Sloping construction of the building in section (walls, columns) is a sollution which provides space which is column free at the top of the building, while intruducing dynamic interior spaces, ideal for commercial function. `,
		'photos': [
			{
				id: 1,
				url: "https://i.imgur.com/0Fn452c.jpg",
				desciption: "no desc provided",
				// aspect: 1.25,
				width: 1650,
				height: 1157,
			},
			{
				id: 2,
				url: "https://i.imgur.com/jWfaKQq.jpg",
				desciption: "no desc provided",
				// aspect: 1.49,
				width: 1800,
				height: 1157,
			},
			{
				id: 3,
				url: "https://i.imgur.com/q1WMZF2.jpg",
				desciption: "no desc provided",
				// aspect: 0.66,
				width: 1600,
				height: 1032,
			},

			{
				id: 4,
				url: "https://i.imgur.com/gR1lE5F.gif",
				desciption: "no desc provided",
				// aspect: 0.66,
				width: 1600,
				height: 840,
			},
			{
				id: 5,
				url: "https://i.imgur.com/Bs5iWYj.gif",
				desciption: "no desc provided",
				width: 1600,
				height: 1011,
			},
			{
				id: 6,
				url: "https://i.imgur.com/HLPEZDi.gif",
				desciption: "no desc provided",
				width: 1600,
				height: 1010
			},
			{
				id: 7,
				url: "https://i.imgur.com/T26ZcEC.jpg",
				desciption: "no desc provided",
				width: 1512,
				height: 2016,
			},
			{
				id: 8,
				url: "https://i.imgur.com/g3vb5CO.jpg",
				desciption: "no desc provided",
				width: 1512,
				height: 2016,
			},
			{
				id: 9,
				url: "https://i.imgur.com/8sylMrZ.jpg",
				desciption: "no desc provided",
				width: 1512,
				height: 2016,
			},
			{
				id: 10,
				url: "https://i.imgur.com/Yo4QdL1.jpg",
				desciption: "no desc provided",
				width: 2016,
				height: 1512,
			},


						

		]
	},
	{
		id: 3,
		name: 'Public Market hall in Sombor',
		year: '2016',
		location: 'Sombor, Serbia',
		client: 'Municipality of Sombor',
		grossArea: '3500sqm',
		architects: 'Petar Pejić, Stefan Jakovljević',
		photography: '',
		status: 'Public Competition, 1st Prize',
		headerPhoto: 'https://i.imgur.com/amfIZnw.jpg',
		descriptionTitle: 'Merging open space with building',
		description: `Public market in Sombor is challenging established architectural – critical attitude towards building in the zone of cultural heritage. New building will acknowledge contextual surrounding, but instead of repeating any of the formal values, it will question each on them, trying to find modern interpretation which will represent current time and programatic of the object. Design of the new market is conceived on understanding development from the past, but also predict future function of the object.
		
		Market hall is conceived as a block with courtyard in the floor plan, resembling the shape of the building which was present on the site in 19th century, while in section it is dynamic in mixing open space with building volume. Building is double height facing the city ring, gradually lifting itself as one moves toward the city centre, resulting in a new type of public space (open market). Public space is placed “under” the building, therefore clearly separating itself from the rest of city, while keeping maximum accessibility from all sides. 
		
		Mixing traditional courtyard typology with open / public space in an unique way results in a new building form, where formal quality of the building is physical manifestation of conceptual manipulation in section.
		`,
		'photos': [
			{
				id: 1,
				url: "https://i.imgur.com/5othdJ2.jpg",
				desciption: "no desc provided",
				// aspect: 1.25,
				width: 1600,
				height: 1067,
			},
			{
				id: 2,
				url: "https://i.imgur.com/0KYXIGZ.jpg",
				desciption: "no desc provided",
				// aspect: 1.49,
				width: 1975,
				height: 1000,
			},
			{
				id: 3,
				url: "https://i.imgur.com/BKgL8ST.jpg",
				desciption: "no desc provided",
				// aspect: 0.66,
				width: 1200,
				height: 800,
			},

			{
				id: 4,
				url: "https://i.imgur.com/o6FCOkK.jpg",
				desciption: "no desc provided",
				// aspect: 0.66,
				width: 1800,
				height: 792,
			},
			{
				id: 5,
				url: "https://i.imgur.com/q7pDfRj.jpg",
				desciption: "no desc provided",
				width: 1500,
				height: 750,
			},
			{
				id: 6,
				url: "https://i.imgur.com/bRbxzpp.jpg",
				desciption: "no desc provided",
				width: 1600,
				height: 677
			},
			{
				id: 7,
				url: "https://i.imgur.com/dDdUAoL.jpg",
				desciption: "no desc provided",
				width: 1014,
				height: 762
			},
			{
				id: 8,
				url: "https://i.imgur.com/XuXobXF.jpg",
				description: "no desc provided",
				width: 3429,
				height: 940,
			},
			{
				id: 9,
				url: "https://i.imgur.com/iSSJxLw.jpg",
				description: "no desc provided",
				width: 1612,
				height: 1912,
			},
						

		]
	},
	{
		id: 4,
		name: 'National Museum of World Writing',
		year: '2017',
		location: 'Songdo, South Korea',
		client: 'private',
		grossArea: '15320sqm',
		architects: 'Petar Pejic, Ivana Marjanovic, Stefan Jakovljevic, Remorker Architects, ATI project',
		photography: '',
		status: 'Public Competition',
		headerPhoto: 'https://i.imgur.com/H0bB9rW.jpg',
		// headerPhoto: 'https://i.imgur.com/fJujpI6.jpg',

		descriptionTitle: 'Stroke on the paper / stroke in the city',
		description: `One has to imagine National Museum of World Writing as a new cultural institution in Songdo with the unique conceptual strength to support museum theme rather than providing generic museum answer. In case of new National Museum of World Writing we found that the question of theme is extremely delicate, since the figurative strength of writing / letter is overwhelming. Furthermore, one has to respect the historical and cultural importance of the writing, while providing contextual  architectural solution. Conceptualizing the method of writing as a series of strokes on the surface, a strong conceptual relation emerges within the museum, where the building is conceived as a stroke in the city. Utilizing the “stroke”as a driving force of the design is a way of extracting the universal essence of writing throughout the history, without referring to specificity. 
		
		The design fuses two opposing characters on the site (nature and park on one side, and dense skyscrapers urbanization on the other) by placing the building as a linear element on the exact border between the two. Horizontality and the shape of the building is embracing the park, while the linearity is seen as a direct relation to the vertical linearity of the skyscrapers in the surrounding. 

		Stroke in the city is as meaningful and careful as it is when it comes to writing. However, unlike the stroke on the paper which is by default two dimensional, new National Museum of World Writing is conceived as a three dimensional abstraction positioned in time and space. Resulting outcome is a surface bending in three dimensions, suggesting its orientation on the site, while providing bend along the stroke. The reading of this surface should be seen as a nonlinear understanding of writing throughout the history of mankind, where each culture was developing a unique way of reading and writing. This understanding of duality and non linear nature of writing is directly translated to the design of the museum, providing a constant shift between the orientation of surfaces inside the museum. While moving through the exhibitions, user is faced with this feeling, where surfaces of the floor are translated into the surfaces of the wall and vice versa. 
		`,
		'photos': [
			{
				id: 1,
				url: "https://i.imgur.com/z6ArPEk.jpg",
				desciption: "no desc provided",
				// aspect: 1.25,
				width: 1600,
				height: 953,
			},
			{
				id: 2,
				url: "https://i.imgur.com/VOISIgq.jpg",
				desciption: "no desc provided",
				// aspect: 1.49,
				width: 1600,
				height: 1066,
			},
			{
				id: 3,
				url: "https://i.imgur.com/VjjJIWb.jpg",
				desciption: "no desc provided",
				// aspect: 0.66,
				width: 1600,
				height: 1600,
			},

			{
				id: 4,
				url: "https://i.imgur.com/BCRpokn.jpg",
				desciption: "no desc provided",
				// aspect: 0.66,
				width: 1600,
				height: 1600,
			},
			{
				id: 5,
				url: "https://i.imgur.com/449HofG.jpg",
				desciption: "no desc provided",
				width: 1600,
				height: 1067,
			},
			{
				id: 6,
				url: "https://i.imgur.com/W27fBpH.jpg",
				desciption: "no desc provided",
				width: 1600,
				height: 1228
			},
			{
				id: 7,
				url: "https://i.imgur.com/bu3FdgT.jpg",
				desciption: "no desc provided",
				width: 1223,
				height: 1500
			},
			{
				id: 8,
				url: "https://i.imgur.com/0pRvICK.jpg",
				description: "no desc provided",
				width: 1600,
				height: 677,
			},
			{
				id: 9,
				url: "https://i.imgur.com/NcZR9LT.jpg",
				description: "no desc provided",
				width: 1600,
				height: 1607,
			},
			{
				id: 10,
				url: "https://i.imgur.com/xVINS4m.jpg",
				description: "no desc provided",
				width: 1600,
				height: 1133,
			},
			{
				id: 11,
				url: "https://i.imgur.com/Tc8WKOd.gif",
				description: "no desc provided",
				width: 1600,
				height: 654,
			},
			{
				id: 12,
				url: "https://i.imgur.com/HDvrila.jpg",
				description: "no desc provided",
				width: 1600,
				height: 2505,
			},
			{
				id: 13,
				url: "https://i.imgur.com/d7m2OOG.gif",
				description: "no desc provided",
				width: 1600,
				height: 949,
			},
			{
				id: 14,
				url: "https://i.imgur.com/w2AePhQ.gif",
				description: "no desc provided",
				width: 1600,
				height: 570,
			},
			
			

		]
	},
	{
		id: 5,
		name: 'Ariano Irpino School',
		year: '2017',
		location: 'Ariano Irpino, Italy',
		client: 'Municipality of Ariano Irpino',
		grossArea: '3500',
		architects: 'Petar Pejic, Ivana Marjanovic, Remorker Architects, ATI project',
		photography: '',
		status: 'competition',
		headerPhoto: 'https://i.imgur.com/LXrsQfE.jpg',
		descriptionTitle: 'Connectivity & Permability',
		description: `New school in Ariano Irpino is placed in a very specific location, occupying a site which has more than 10m vertical displacement and is quite narrow for a given volume of the school. Furthermore, the whole city is characterized with vertical displacement in the terrain resulting in an extraordinary number of public stairs. Also, this displacement in the topography led to a very specific urban condition of horizontal shifts between buidling and houses in order to adopt to those conditions. New school embrace all of those condition as a specificity which will give a character and a new quality to the project.
		`,
		'photos': [
			{
				id: 1,
				url: "https://i.imgur.com/dE9iRv9.jpg",
				desciption: "no desc provided",
				// aspect: 1.25,
				width: 1600,
				height: 993,
			},
			{
				id: 2,
				url: "https://i.imgur.com/vXTPacj.jpg",
				desciption: "no desc provided",
				// aspect: 1.49,
				width: 1600,
				height: 914,
			},
			{
				id: 3,
				url: "https://i.imgur.com/DpLIDpr.jpg",
				desciption: "no desc provided",
				// aspect: 0.66,
				width: 1600,
				height: 1809,
			},

			{
				id: 4,
				url: "https://i.imgur.com/Ktxm0Ij.jpg",
				desciption: "no desc provided",
				// aspect: 0.66,
				width: 1600,
				height: 1600,
			},
			{
				id: 5,
				url: "https://i.imgur.com/jKQZXb4.jpg",
				desciption: "no desc provided",
				width: 1600,
				height: 1600,
			},
			{
				id: 6,
				url: "https://i.imgur.com/E722ZFs.jpg",
				desciption: "no desc provided",
				width: 1600,
				height: 800,
			},
			{
				id: 7,
				url: "https://i.imgur.com/8oFNA4X.jpg",
				desciption: "no desc provided",
				width: 1600,
				height: 1205,
			},
			{
				id: 8,
				url: "https://i.imgur.com/Kofvfnb.jpg",
				description: "no desc provided",
				width: 1600,
				height: 1914,
			},
			{
				id: 9,
				url: "https://i.imgur.com/6oJ4YR1.gif",
				description: "no desc provided",
				width: 1600,
				height: 1003,
			},
						

		]
	},
]

// export projects;

export var getProject = (id) => {
	return projects.find(project => project.id === Number(id));	
}